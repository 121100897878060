import { defineStore } from 'pinia'
import {apiDomain, getHeader } from '@/assets/js/config.js'
import axios from 'axios'

export const useLoginInfoStore = defineStore('login_info', {
  // other options...
  state:() => ({
      login_info:[],
  }),
  getters: {
    getLoginInfo(){
        return this.login_info
      }
  },
  actions : {
    async fetchLoginInformation(){
        try {
            const data = await axios.get(apiDomain + 'setup/user.info', { headers: getHeader() })
                
                this.login_info = data.data

                // this.login_info.id = data.data.id
                // this.login_info.role_id = data.data.role_id
                // this.login_info.name = data.data.name
                // this.login_info.email = data.data.email
                // this.login_info.hod = data.data.hod              

                // console.log(this.login_info)
              
        }catch (error) {
            alert(error)
            console.log(error)            
        }

    }
  }
})